<script lang="ts">
export default {
    name: 'VersionTag',
    setup() {
    },
    data() {
        return {
            api_version: '',
            api_env: 'PRODUCTION',
            releases_url: null,
        }
    },
    mounted() {
        const api = this.$api as ApiService
        api.setRequest({
            url: '',
            method: 'GET',
        }).then(async (data) => {
            this.api_version = `v${data?.pretty_version}` ?? '?'
            this.api_env = data?.env?.toUpperCase()
            this.releases_url = data?.releases_url
        })
    },
}
</script>

<template>
    <small class="api-version">
        <a
            v-if="releases_url"
            :href="releases_url"
            target="_blank"
            title="Releases notes"
        >{{ api_version }}</a>
        <template v-else>{{ api_version }}</template>

        <v-badge
            v-if="'PRODUCTION' !== api_env"
            :color="api_env === 'LOCAL' ? 'danger' : 'warning'"
            :content="api_env"
            inline
        />

        <a v-if="api_env === 'PRODUCTION' && $config.public.uptime_redirect_url_project" :href="`https://uptime.datakode.fr/status/${$config.public.uptime_redirect_url_project}`" target="_blank" class="text-info">
            Status des services
        </a>
    </small>
</template>
